<template>
  <div>
    <ValidationObserver v-slot:default="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อประเภท</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อโซน (TH)"
                rules="required"
              >
                <vs-input
                  v-model="formData.title.th.value"
                  class="w-full required"
                  name="title"
                  label="TH"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อโซน (EN)"
              >
                <vs-input
                  v-model="formData.title.en.value"
                  class="w-full mt-4"
                  name="title-en"
                  label="EN"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel />
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อโซน (CN)"
              >
                <vs-input
                  v-model="formData.title.cn.value"
                  class="w-full mt-4"
                  name="title-cn"
                  label="CN"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span class="required">ระบุ URL พิเศษ (Slug)</span> <span class="text-danger"> *</span><br>
              <span class="text-warning text-sm">URL พิเศษนี้ใช้รวมกันทั้ง 3 ภาษา</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Slug" 
                rules="required|special_charecter">
                <vs-input
                  v-model="formData.slug"
                  class="w-full"
                  name="title"
                  @focus="() => isSlugExist = false"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
                <br v-show="isSlugExist && errors.length > 0" />
                <span 
                  v-show="isSlugExist" 
                  class="text-danger text-sm"
                >slug นี้มีในระบบแล้ว กรุณากรอกข้อมูลใหม่</span
                >
              </ValidationProvider>
              <span class="text-gray flex flex-col">ตัวอย่าง slug: singlehome, townhome</span>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel />
          <FormContent>
            <vs-button 
              class="mt-8 w-1/3" 
              @click="submit(context)"
            >Submit</vs-button
            >
          </FormContent>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import get from 'lodash/get'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import useNotify from '@/use/useNotify'
import moment from 'moment'

export default {
  name: 'TypeForm',
  components: {
    ValidationObserver,
  },
  setup(props, ctx) {
    const {
      root: { $vs, $store, $router },
    } = ctx
    const routePrefix = ref('property-type')
    const isSlugExist = ref(false)
    const formData = reactive({
      id: null,
      slug: null,
      title: {
        th: {
          id: null,
          value: '',
        },
        en: {
          id: null,
          value: '',
        },
        cn: {
          id: null,
          value: '',
        },
      },
      code: 'singlehome',
      property_type: 'house',
      created_by: null,
      updated_by: null,
      updated_at: null,
    })

    const { success: notifySuccess, error: notifyError } = useNotify(ctx)

    onMounted(() => {
      const id = get($router.currentRoute.params, 'id', null)
      if (id) {
        formData.id = id
        $store
          .dispatch('propertyType/fetchOne', id)
          .then((result) => {
            formData.code = result.code
            formData.slug = result.slug
            formData.property_type = result.property_type
            formData.created_by = result.created_by
            formData.updated_by = result.updated_by
            formData.updated_at = result.updated_at

            const lang = ['th', 'en', 'cn']
            forEach(lang, (item) => {
              const data = find(result.property_category_translates, ['lang', item])
              formData.title[item].id = data ? data.id : null
              formData.title[item].value = data ? data.value : ''
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    const submit = async ({ validate }) => {
      if (formData.slug) {
        formData.slug =  formData.slug.replace(/\s+/g, '-')
        const isExist = await $store.dispatch('propertyType/checkSlugExist', formData)
        isSlugExist.value = isExist

        if (isExist) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0

          return notifyError({
            text: "มี Slug นี้ในระบบแล้ว"
          })
        }
      }

      validate().then((isValid) => {
        if (!isValid) {
          // scroll to top when not input slug
          if (!formData.slug) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            return notifyError({
              text: "กรุณาระบุ Slug"
            })
          }
          
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลที่กรอก',
          })
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        // add user created by
        if (!formData.created_by) {
          created_by = email
        }
        const updated_by = email

        $store
          .dispatch(
            `propertyType/${formData.id === null ? 'addItem' : 'update'}`,
            {
              ...formData,
              created_by,
              updated_by
            }
          )
          .then(() => {
            $router.push({ name: 'property-type' })
            $vs.loading.close()
            notifySuccess({
              text: `${isEmpty(formData.id) ? 'เพิ่ม' : 'แก้ไข'}ข้อมูลเรียบร้อย`,
            })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const formatDate = (date) => {
      moment.locale('th')
      return date ? moment(date).format('LLL') : ''
    }

    return {
      formData,
      routePrefix,
      submit,
      formatDate,
      isSlugExist
    }
  },
}
</script>

<style lang="scss">
  .required {
    label:after {
      content: ' *';
      color: red;
    }
  }
</style>

